const AdminRoutes = {
  path: '/admin',
  component: () => import('@/layouts/full/FullLayout.vue'),
  meta: {
    requiresAuth: true,
  },
  redirect: '/admin/dashboard',
  children: [
    {
      //HOME - 대시보드
      name: 'Dashboard',
      path: '/admin/dashboard',
      component: () => import('@/views/admin/dashboard/index.vue'),
    },
    {
      //HOME - 공지사항
      name: 'Notice',
      path: '/admin/notice',
      component: () => import('@/views/admin/dashboard/notice/index.vue'),
    },
    {
      path: 'notice/:id',
      name: 'NoticeDetail',
      component: () => import('@/views/admin/dashboard/notice/detail.vue'),
    },
    {
      //병원기능 - 파트너스 검색
      name: 'HospitalPartnersSearch',
      path: '/hospital/partners',
      component: () => import('@/views/admin/hospital/partners/index.vue'),
    },
    {
      //병원기능 - 파트너스 상세
      name: 'HospitalPartnersDetail',
      path: '/hospital/partners/:id',
      component: () => import('@/views/admin/hospital/partners/detail.vue'),
    },
    {
      //병원기능 - 캠페인 관리
      name: 'HospitalCampaign',
      path: '/hospital/campaign',
      component: () => import('@/views/admin/hospital/campaign/index.vue'),
    },
    {
      //병원기능 - 성과분석
      name: 'HospitalAnalysis',
      path: '/hospital/analysis',
      component: () => import('@/views/admin/hospital/analysis.vue'),
    },
    {
      //병원기능 - 설정
      name: 'HospitalSetting',
      path: '/hospital/setting',
      component: () => import('@/views/admin/hospital/setting.vue'),
    },

    {
      //파트너스기능 - 병원 검색
      name: 'HospitalHospitalSearch',
      path: '/partners/hospital',
      component: () => import('@/views/admin/partners/hospital/index.vue'),
    },
    {
      //파트너스기능 - 병원 상세
      name: 'PartnersHospitalDetail',
      path: '/partners/hospital/:id',
      component: () => import('@/views/admin/partners/hospital/detail.vue'),
    },
    {
      //파트너스기능 - 마케팅 관리
      name: 'PartnersProject',
      path: '/partners/project',
      component: () => import('@/views/admin/partners/project/index.vue'),
    },
    {
      //파트너스기능 - 마케팅 상세
      name: 'PartnersProjectDetail',
      path: '/partners/project/:id',
      component: () => import('@/views/admin/partners/project/detail.vue'),
    },
    {
      //파트너스기능 - 통역 정산 관리
      name: 'PartnersBilling',
      path: '/partners/billing',
      component: () => import('@/views/admin/partners/billing/index.vue'),
    },
    {
      //파트너스기능 - 통역 정산 상세
      name: 'PartnersBillingDetail',
      path: '/partners/billing/:id',
      component: () => import('@/views/admin/partners/billing/detail.vue'),
    },
    {
      //파트너스기능 - 성과분석
      name: 'PartnersAnalysis',
      path: '/partners/analysis',
      component: () => import('@/views/admin/partners/analysis.vue'),
    },
    {
      //파트너스 - 설정
      name: 'PartnersSetting',
      path: '/partners/setting',
      component: () => import('@/views/admin/partners/setting.vue'),
    },
    {
      //공통기능 - 매칭관리
      name: 'Message',
      path: '/admin/matching',
      component: () => import('@/views/admin/common/matching.vue'),
    },

    {
      //공통기능 - 마이페이지
      name: 'Mypage',
      path: '/admin/mypage',
      component: () => import('@/views/admin/common/mypage.vue'),
    },
    {
      name: 'Alert',
      path: '/ui/alerts',
      component: () => import('@/views/ui-components/Alerts.vue'),
    },
    {
      name: 'Buttons',
      path: '/ui/buttons',
      component: () => import('@/views/ui-components/Buttons.vue'),
    },
    {
      name: 'Cards',
      path: '/ui/cards',
      component: () => import('@/views/ui-components/Cards.vue'),
    },
    {
      name: 'Tables',
      path: '/ui/tables',
      component: () => import('@/views/ui-components/Tables.vue'),
    },
    {
      name: 'Icons',
      path: '/icons',
      component: () => import('@/views/pages/Icons.vue'),
    },
    {
      name: 'Starter',
      path: '/sample-page',
      component: () => import('@/views/pages/SamplePage.vue'),
    },
  ],
};

export default AdminRoutes;
