import cryptojs from 'crypto-js';

const ID_TOKEN_KEY = 'mt_token';
const ID_USER_KEY = 'user_info';
let _mt_storage = window.localStorage;

function lg() {
  let args = Array.prototype.slice.call(arguments);
  console.log.apply(console, args);
}

const encodingSession = function (userInfo) {
  let wordArray = cryptojs.enc.Utf8.parse(userInfo);
  return cryptojs.enc.Base64.stringify(wordArray);
};

const decodingSession = function (encUserInfo) {
  return cryptojs.enc.Base64.parse(encUserInfo).toString(cryptojs.enc.Utf8);
};

export const getToken = () => {
  let token = _mt_storage.getItem(ID_TOKEN_KEY);
  if (token) {
    return decodingSession(token);
  } else {
    return null;
  }
};

export const saveToken = token => {
  token = encodingSession(token);
  _mt_storage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  _mt_storage.removeItem(ID_TOKEN_KEY);
};

export const saveUser = user => {
  try {
    user = encodingSession(JSON.stringify(user));
    _mt_storage.setItem(ID_USER_KEY, user);
  } catch (e) {
    lg(e);
  }
};

export const getUser = () => {
  let user = _mt_storage.getItem(ID_USER_KEY);
  if (user) {
    try {
      return JSON.parse(decodingSession(user));
    } catch (e) {
      lg(e);
      return null;
    }
  } else {
    return null;
  }
};

export const destroyUser = () => {
  _mt_storage.removeItem(ID_USER_KEY);
};

export const destroyAllItems = () => {
  _mt_storage.removeItem(ID_TOKEN_KEY);
  _mt_storage.removeItem(ID_USER_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveUser,
  getUser,
  destroyUser,
  destroyAllItems,
};
