<!-- VCustomDate.vue -->
<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '날짜 선택',
  },
});

const emit = defineEmits(['update:modelValue']);

const date = ref(props.modelValue);
const datePicker = ref(null);

const formattedDate = computed(() => {
  if (!date.value) return '';
  const [year, month, day] = date.value.split('-');
  return `${year}-${month}-${day}`;
});

const datePickerStyle = computed(() => ({
  position: 'absolute',
  opacity: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
}));

const openDatePicker = () => {
  if (datePicker.value) {
    datePicker.value.showPicker();
  }
};

const updateDate = event => {
  const newDate = event.target.value;
  date.value = newDate;
  emit('update:modelValue', newDate);
};
</script>
<template>
  <div class="v-custom-date">
    <v-text-field
      :model-value="formattedDate"
      :label="label"
      readonly
      @click="openDatePicker"
      append-inner-icon="mdi-calendar"
      @click:append="openDatePicker"
    ></v-text-field>
    <input
      type="date"
      :value="date"
      @input="updateDate"
      ref="datePicker"
      :style="datePickerStyle"
    />
  </div>
</template>

<style scoped>
.v-custom-date {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
</style>
