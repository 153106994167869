import { defineStore } from 'pinia';
import jwt from './../src/assets/scripts/jwt';

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      token: jwt.getToken(),
      currentUser: jwt.getUser(),
    };
  },
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    login(obj) {
      this.token = obj.token;
      this.currentUser = obj.user;
      jwt.saveToken(obj.token);
      jwt.saveUser(obj.user);
    },
    logout() {
      this.token = null;
      this.currentUser = null;
      jwt.destroyAllItems();
    },
  },
});
