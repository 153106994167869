const MainRoutes = {
    path: '/',
    meta: {
        requiresAuth: true
    },
    redirect: '/',
    component: () => import('@/layouts/main/MainLayout.vue'),
    children: [
        {
            name: 'Main',
            path: '/',
            component: () => import('@/views/main/index.vue')
        },
    ]
};

export default MainRoutes;
