import { createApp } from 'vue';
import { createPinia } from 'pinia';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import VueApexCharts from 'vue3-apexcharts';
import VueTablerIcons from 'vue-tabler-icons';
import Maska from 'maska';

import App from './App.vue';
import { router } from './router';
import vuetify from './plugins/vuetify';
import global_const from '../plugin/global_const';
import main_api from '../plugin/main_api';
import tool_app from '../plugin/tool_app';
import VCustomDate from '@/components/shared/VCustomDate.vue';

import '@/scss/style.scss';

const app = createApp(App);

app.config.globalProperties.$global_const = global_const;
app.config.globalProperties.$main_api = main_api;
app.config.globalProperties.$tool_app = tool_app;

if (import.meta.env.VITE_ENV_NAME === 'production') {
  const script_clarity = document.createElement('script');
  script_clarity.src = '/assets/js/clarity.js';
  document.head.appendChild(script_clarity);

  const script_gtag = document.createElement('script');
  script_gtag.src = '/assets/js/gtag.js';
  document.head.appendChild(script_gtag);

  const script_gtag_async = document.createElement('script');
  script_gtag_async.src = 'https://www.googletagmanager.com/gtag/js?id=G-QD3GDF1J3D';
  script_gtag_async.async = true;
  document.head.appendChild(script_gtag_async);
}

app.component('v-custom-date', VCustomDate);

app.use(router);
app.use(PerfectScrollbar);
app.use(createPinia());
app.use(VueTablerIcons);
app.use(Maska);
app.use(VueApexCharts);
app.use(vuetify).mount('#app');
